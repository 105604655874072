import request from "../core/request";

export default {
  // Создание баннера через /api/admin/banner/add
  createBanner(input) {
    // input должен содержать:
    // name, departmentId, published, storeOpeningDepartment, categoryId, bannerTypeId
    return request.execute("post", "admin/banner/add", input);
  },

  // Редактирование баннера через /api/admin/banner/edit
  updateBanner(input) {
    // input должен содержать: id, name, position, departmentId, storeOpeningDepartment, categoryId, bannerTypeId, published
    return request.execute("put", "admin/banner/edit", input);
  },

  // Метод загрузки изображений, теперь передаём три имени файлов:
  // desktopImageName, mobileImageName, mobileWebImageName
  addImageForBanner(input) {
    return request.execute(
      "post",
      "admin/banner/add/image?" +
        `bannerId=${input.bannerId}&` +
        `desktopImageName=${input.desktopImageName}&` +
        `mobileImageName=${input.mobileImageName}&` +
        `mobileWebImageName=${input.mobileWebImageName}`,
      input.file,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  },

  getBannerList(departmentId) {
    return request.execute(
      "get",
      `admin/banner/get/all/byDepartmentId/${departmentId}`
    );
  },

  getBannerImage(input) {
    return request.execute(
      "get",
      `admin/banner/get/image/${input.bannerId}/${input.type}`
    );
  },

  deleteBanner(bannerId) {
    return request.execute("delete", `admin/banner/delete/${bannerId}`);
  },

  addTypeToBanner(payload) {
    return request.execute(
      "post",
      `admin/banner/add/type/${payload.bannerId}/${payload.typeId}`
    );
  },

  addCategoryToBanner(body) {
    return request.execute(
      "put",
      `admin/banner/editCategoryOrDepartment`,
      body
    );
  },

  addProductToBanner(payload) {
    return request.execute(
      "post",
      `admin/banner/add/bannerProduct/${payload.bannerId}/${payload.productId}`
    );
  },

  // Получение баннеров для категорий
  getCategoryBanners(cityId) {
    return request.execute("get", `admin/banner/get/categoryBanners/${cityId}`);
  },

  // Создание баннера для категории – используем тот же endpoint с нужными defaults
  createCategoryBanner(payload) {
    const fullPayload = {
      name: payload.name,
      departmentId: payload.departmentId,
      published: true,
      storeOpeningDepartment: payload.departmentId,
      categoryId: payload.categoryId || null,
      bannerTypeId: payload.bannerTypeId || null,
    };
    return request.execute("post", "admin/banner/add", fullPayload);
  },

  bindBannerWithCategory(payload) {
    return request.execute(
      "post",
      `admin/banner/link/category/banner`,
      payload
    );
  },

  changeBannerVisibility(bannerId) {
    return request.execute("get", `admin/banner/published/${bannerId}`);
  },

  // Новый метод для получения типов баннеров
  getBannerTypes() {
    return request.execute("get", "admin/banner/get/types");
  },
};
