/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "CATEGORY";
const namespaced = true;

const state = {
  cityEmployee: [],
  // список объектов каждый из которых представляет собой
  // уровень ретинга для расчета премии у сборщиков: DIS-1031
  ratingLevelsList: [],
  ratingLevelsListV2: [],
  revards: null,
  contracts: [],
};

const getters = {
  STATE: (s) => s,
  RATING_LEVELS: (s) => s.ratingLevelsList,
  RATING_LEVELS_V2: (s) => s.ratingLevelsListV2,
  CITY_EMPLOYEE: (s) => s.cityEmployee,
  REVARDS: (s) => s.revards,
};

const mutations = {
  SET_EMPLOYEE_LIST: (state, data) => {
    if (data.type === "success") {
      state.cityEmployee = data.data;
    }
  },
  SET_RATING_LEVELS_LIST: (state, data) => {
    state.ratingLevelsList = data;
  },
  SET_RATING_LEVELS_V2: (state, data) => {
    state.ratingLevelsListV2 = data;
  },
  SET_REWARDS: (state, data) => {
    state.revards = data;
  },
  SET_CONTRACTS: (state, data) => {
    state.contracts = data;
  },
};

const actions = {
  GET_RATING_LEVELS: async ({ commit }, payload) => {
    let data = await api.Employee.getRatingLevels(payload);
    commit("SET_RATING_LEVELS_LIST", data);
  },
  GET_RATING_LEVELS_V2: async ({ commit }, payload) => {
    let data = await api.Employee.getRatingLevelsV2(payload);
    commit(
      "SET_RATING_LEVELS_V2",
      data.sort((item1, item2) => item1.level - item2.level)
    );
  },
  GET_REWARDS: async ({ commit }, payload) => {
    let data = await api.Employee.getRewardByDepartment(payload);
    commit("SET_REWARDS", data);
  },
  GET_CONTRACTS: async ({ commit }) => {
    let data = await api.Employee.getAllContracts();
    commit("SET_CONTRACTS", data);
  },
  CHANGE_RATING_LEVELS: async ({ commit }, payload) => {
    await api.Employee.setRatingLevel(payload);
  },
  CHANGE_RATING_LEVELS_V2: async ({ commit }, payload) => {
    await api.Employee.setRatingLevel2(payload);
  },
  GET_CITY_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.employeeCity(payload);
    commit("SET_EMPLOYEE_LIST", data);
  },

  GET_CITY_DELETED_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.deletedEmployeeCity(payload);
    commit("SET_EMPLOYEE_LIST", data);
  },
  RESTORE_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.restoreEmployee(payload);
    return data;
  },

  ADD_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.addEmployee(payload);
    return data;
  },
  GET_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.getEmployee(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.editEmployee(payload);
    return data;
  },
  DELETE_EMPLOYEE: async ({ commit }, payload) => {
    let data = await api.Employee.deleteEmployee(payload);
    return data;
  },
  GET_COURIER_LIST_MAP: async ({ commit }, payload) => {
    let data = await api.Employee.getCourierListMap(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  GET_COURIER_LIST_MAP_ORDER: async ({ commit }, payload) => {
    let data = await api.Employee.getCourierListMapOrder(payload);
    if (data.type == "success") return data.data;
    return [];
  },
    // Обновлённое действие для получения курьеров через POST
  GET_COURIER: async ({ commit }, payload) => {
    try {
      console.log("[GET_COURIER] payload:", payload); // Логируем отправляемые параметры
      let data = await api.Employee.getCourier(payload);
      console.log("[GET_COURIER] response:", data); // Логируем ответ от API
      return data;
    } catch (error) {
      console.error("Ошибка при получении курьеров:", error);
      return { type: "error", data: [] };
    }
  },
  // Обновлённое действие для получения сборщиков через POST
  GET_PICKER: async ({ commit }, payload) => {
    try {
      console.log("[GET_PICKER] payload:", payload); // Логируем отправляемые параметры
      let data = await api.Employee.getPicker(payload);
      console.log("[GET_PICKER] response:", data); // Логируем ответ от API
      return data;
    } catch (error) {
      console.error("Ошибка при получении сборщиков:", error);
      return { type: "error", data: [] };
    }
  },

};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
