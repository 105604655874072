import api from "@/api";

const name = "SHOWCASE_WINDOW";
const namespaced = true;

const state = {
  // Хранит витрины для выбранного типа и устройства
  showcases: [],
  // Список доступных типов витрин (например, carousel, module, background, common, promo)
  types: [],
};

const getters = {
  allShowcases: (state) => state.showcases,
  showcaseTypes: (state) => state.types,
};

const mutations = {
  SET_SHOWCASE_WINDOWS(state, payload) {
    state.showcases = payload;
  },
  SET_SHOWCASE_TYPES(state, types) {
    state.types = types;
  },
};

const actions = {
  /**
   * Создание витрины.
   * payload – объект с данными витрины (см. API)
   */
  async createShowcaseWindow({ commit }, payload) {
    const data = await api.ShowcaseWindow.createShowcaseWindow(payload);
    return data;
  },

  /**
   * Получение доступных типов витрин для выбранного устройства.
   */
  async fetchShowcaseTypes({ commit }, device) {
    const data = await api.ShowcaseWindow.getShowcaseTypes(device);
    commit("SET_SHOWCASE_TYPES", data);
    return data;
  },

  /**
   * Получение витрин для указанного устройства и типа.
   * options: { device, type }
   */
  async fetchShowcaseWindow({ commit }, options) {
    const data = await api.ShowcaseWindow.getShowcaseWindow(options);
    commit("SET_SHOWCASE_WINDOWS", data);
    return data;
  },

  /**
   * Редактирование витрины.
   */
  async editShowcaseWindow({ commit }, payload) {
    const data = await api.ShowcaseWindow.editShowcaseWindow(payload);
    return data;
  },

  /**
   * Удаление категории из витрины.
   */
  async deleteShowcaseCategory({ commit }, windowCategoryId) {
    const data = await api.ShowcaseWindow.deleteShowcaseCategory(windowCategoryId);
    return data;
  },

  /**
   * Удаление продукта из витрины.
   */
  async deleteShowcaseProduct({ commit }, windowProductId) {
    const data = await api.ShowcaseWindow.deleteShowcaseProduct(windowProductId);
    return data;
  },

  /**
   * Изменение порядка категорий внутри витрины.
   * payload – массив объектов { id, position }
   */
  async updateShowcaseCategoriesOrder({ commit }, payload) {
    const data = await api.ShowcaseWindow.updateShowcaseCategoriesOrder(payload);
    return data;
  },

  /**
   * Изменение порядка продуктов внутри витрины.
   * payload – массив объектов { id, position }
   */
  async updateShowcaseProductsOrder({ commit }, payload) {
    const data = await api.ShowcaseWindow.updateShowcaseProductsOrder(payload);
    return data;
  },
  // Новые actions
  async addShowcaseCategory({ commit }, { windowId, categoryId }) {
    const data = await api.ShowcaseWindow.addShowcaseCategory(windowId, categoryId);
    return data;
  },
  async addShowcaseProduct({ commit }, { windowId, productId }) {
    const data = await api.ShowcaseWindow.addShowcaseProduct(windowId, productId);
    return data;
  },
    // Новый action для обновления позиций витрин
    async updateShowcasePositions({ commit }, payload) {
      const data = await api.ShowcaseWindow.updateShowcasePositions(payload);
      return data;
    },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
