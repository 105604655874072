import ProvidersListAPI from "@/api/modules/ProvidersList";

const name = "ProvidersList";
const namespaced = true;

const state = {
  // Здесь будут храниться данные о филиалах (departments)
  departments: [],
};

const getters = {
  STATE: (state) => state,
};

const mutations = {
  // Сохраняем полученный список филиалов в состояние
  SET_DEPARTMENTS(state, data) {
    state.departments = data;
  },
};

const actions = {
  /**
   * Запрашивает с сервера список филиалов для поставщиков
   */
  async GET_ALL_DEPARTMENTS({ commit }) {
    const response = await ProvidersListAPI.getAllDepartments();
    console.log("GET_ALL_DEPARTMENTS response:", response);
    // Если ответ уже массив, то просто сохраняем его
    if (Array.isArray(response)) {
      commit("SET_DEPARTMENTS", response);
    } else if (response.type === "success") {
      commit("SET_DEPARTMENTS", response.data);
    } else {
      commit("SET_DEPARTMENTS", []);
    }
    return response;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
