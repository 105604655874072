/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "DEPARTMENT_GROUPS";
const namespaced = true;

const state = {
  groups: [],
};

const getters = {
  // Возвращает массив всех групп филиалов
  STATE: (state) => state.groups,
};

const mutations = {
  SET_GROUPS: (state, data) => {
    // Проверка на ошибку (можно обработать и иначе)
    if (data.type !== "error") {
      state.groups = data;
    }
  },
};

const actions = {
  // Получить все группы
  async GET_ALL_GROUPS({ commit }) {
    const response = await api.DepartmentGroups.getAll();
    commit("SET_GROUPS", response);
  },

  // Создать группу
  async ADD_GROUP({ dispatch }, payload) {
    const response = await api.DepartmentGroups.addGroup(payload);
    // можно обновлять сразу список групп
    if (response.type !== "error") {
      await dispatch("GET_ALL_GROUPS");
    }
    return response;
  },

  // Редактировать группу
  async EDIT_GROUP({ dispatch }, payload) {
    const response = await api.DepartmentGroups.editGroup(payload);
    if (response.type !== "error") {
      await dispatch("GET_ALL_GROUPS");
    }
    return response;
  },

  // Удалить группу
  async DELETE_GROUP({ dispatch }, id) {
    const response = await api.DepartmentGroups.deleteGroup(id);
    if (response.type !== "error") {
      await dispatch("GET_ALL_GROUPS");
    }
    return response;
  },

  // Привязать филиал к группе
  async ADD_GROUP_TO_DEPARTMENT(context, payload) {
    // здесь payload = { departmentId, groupId }
    return await api.DepartmentGroups.addGroupToDepartment(payload);
  },

  // Удалить филиал из группы
  async REMOVE_GROUP_FROM_DEPARTMENT(context, departmentId) {
    return await api.DepartmentGroups.removeGroupFromDepartment(departmentId);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
