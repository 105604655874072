import api from "@/api";

const name = "STORIES";
const namespaced = true;

const state = {
  // Список сторисов для админа
  allStories: [],
  // Список сторисов для конкретного поставщика
  providerStories: []
};

const getters = {
  ALL_STORIES: (state) => state.allStories,
  PROVIDER_STORIES: (state) => state.providerStories,
};

const mutations = {
  SET_ALL_STORIES(state, stories) {
    state.allStories = stories;
  },
  SET_PROVIDER_STORIES(state, stories) {
    state.providerStories = stories;
  },
};

const actions = {
  // Создание нового сториса
  CREATE_STORY: async ({ commit }, { storyData, file }) => {
    return await api.Stories.createStory(storyData, file);
  },

  // Редактирование сториса
  UPDATE_STORY: async ({ commit }, { storyId, storyData, file }) => {
    return await api.Stories.updateStory(storyId, storyData, file);
  },

  // Получение всех сторисов (для админа)
  GET_ALL_STORIES: async ({ commit }) => {
    let data = await api.Stories.getAllStories();
    commit("SET_ALL_STORIES", data);
  },

  // Получение сторисов для конкретного поставщика
  GET_PROVIDER_STORIES: async ({ commit }, providerId) => {
    let data = await api.Stories.getStoriesByProvider(providerId);
    commit("SET_PROVIDER_STORIES", data);
  },

  // Удаление сториса
  DELETE_STORY: async ({ commit }, storyId) => {
    return await api.Stories.deleteStory(storyId);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
