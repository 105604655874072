import request from "../core/request";

export default {
  // Создание сториса (file обязателен)
  createStory(storyData, file) {
    const formData = new FormData();
    formData.append(
      "storyData",
      new Blob([JSON.stringify(storyData)], { type: "application/json" })
    );
    // При создании файл обязателен и ключ называется "file"
    formData.append("file", file);

    return request.executeFile("post", "/provider/stories/create", formData);
  },

  // Редактирование сториса (file опционален)
  updateStory(storyId, storyData, file) {
    const formData = new FormData();
    formData.append(
      "storyData",
      new Blob([JSON.stringify(storyData)], { type: "application/json" })
    );
    // Если файл не выбран, просто не добавляем logoImage
    if (file) {
      formData.append("logoImage", file);
    }

    return request.executeFile(
      "put",
      `/provider/stories/update/${storyId}`,
      formData
    );
  },

  // Получение сторисов для конкретного поставщика
  getStoriesByProvider(providerId) {
    return request.execute("get", `/provider/stories/get/all/${providerId}`);
  },

  // Получение всех сторисов (для админа)
  getAllStories() {
    return request.execute("get", `/provider/stories/get/all`);
  },

  // Удаление сториса
  deleteStory(storyId) {
    return request.execute("delete", `/provider/stories/delete/${storyId}`);
  },
};
