import request from "../core/request";

export default {
  /**
   * Получает список филиалов для списка поставщиков
   * GET: /api/admin/department/all/dto
   */
  getAllDepartments() {
    return request.execute("get", "admin/department/all/dto");
  },
};
