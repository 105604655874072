/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "VitrinTags";
const namespaced = true;

const state = {
  allTags: [],
};

const getters = {
  STATE: (state) => state.allTags,
};

const mutations = {
  SET_ALL_TAGS: (state, data) => {
    state.allTags = data;
  },
};

const actions = {
  // Получаем все теги
  GET_ALL_TAGS: async ({ commit }) => {
    const data = await api.VitrinTags.getAll();
    commit("SET_ALL_TAGS", data);
  },
  // Создание нового тега
  CREATE_TAG: async ({ commit }, payload) => {
    const data = await api.VitrinTags.createTag(payload);
    return data;
  },
  // Обновление (редактирование) тега
  UPDATE_TAG: async ({ commit }, payload) => {
    const data = await api.VitrinTags.changeTag(payload);
    return data;
  },
  // Удаление тега
  DELETE_TAG: async ({ commit }, id) => {
    const data = await api.VitrinTags.deleteTag(id);
    return data;
  },
  // Загрузка SVG-иконки (используется как для веб, так и для мобильной версии)
  UPLOAD_TAG_IMAGE: async ({ commit }, { id, formData }) => {
    const data = await api.VitrinTags.uploadTagImage(id, formData);
    return data;
  },
  // Удаление SVG-иконки
  DELETE_TAG_IMAGE: async ({ commit }, id) => {
    const data = await api.VitrinTags.deleteTagImage(id);
    return data;
  },
  // Публикация тега для сайта
  PUBLISH_TAG_WEB: async ({ commit }, { cityId, body }) => {
    const data = await api.VitrinTags.publishTagWeb(cityId, body);
    return data;
  },
  // Публикация тега для мобильного приложения
  PUBLISH_TAG_MOBILE: async ({ commit }, { cityId, body }) => {
    const data = await api.VitrinTags.publishTagMobile(cityId, body);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
