import request from "../core/request";

export default {
  getAll() {
    return request.execute("get", "admin/homepage-tags");
  },
  deleteTag(id) {
    return request.execute("delete", `admin/homepage-tags/${id}`);
  },
  createTag(body) {
    return request.execute("post", "admin/homepage-tags", body);
  },
  changeTag(body) {
    return request.execute("put", `admin/homepage-tags/${body.id}`, body);
  },
   // Новый метод для загрузки иконки
   uploadTagImage(id, formData) {
    return request.execute("post", `admin/homepage-tags/image/${id}`, formData);
  },
  // Новый метод для удаления иконки
  deleteTagImage(id) {
    return request.execute("delete", `admin/homepage-tags/image/${id}`);
  },
  // Если нужно публиковать тег для определённого города (сайт/мобильная версия)
  publishTagWeb(cityId, body) {
    return request.execute("post", `web/homepage-tags/by-city/${cityId}`, body);
  },
  publishTagMobile(cityId, body) {
    return request.execute("post", `mobile/homepage-tags/by-city/${cityId}`, body);
  },
};
