/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "ORDER";
const namespaced = true;
import showMessage from "@/Functions/message";

const state = {
  allOrder: [],
  moreOrder: [],
  allPreOrder: [],
  searchingOrder: null,
  orderStatistics: [], //используется в данных о заказах
};

const getters = {
  STATE: (state) => {
    return state;
  },
  STATISTICS: (state) => {
    return state.orderStatistics;
  },
};

const mutations = {
  SET_ORDER_ALL: (state, data) => {
    state.allOrder = data;
  },
  SET_SERCH_ORDER: (state, data) => {
    state.searchingOrder = data;
  },
  SET_PRE_ORDER_ALL: (state, data) => {
    state.allPreOrder = data;
  },
  SET_MORE_ORDER: (state, data) => {
    let moreOrder = data.content.filter((el) => {
      if (!state.allOrder.content.includes(el)) {
        return el;
      }
    });
    state.allOrder.content.push(...moreOrder);
  },
  SET_STATISTICS: (state, data) => {
    state.orderStatistics = data;
  },
};

const actions = {
  GET_ORDERS_STATISTICS: async ({ commit }, payload) => {
    let data = await api.Order.orderStatistics(payload);
    commit("SET_STATISTICS", data);
  },
  GET_ALL_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.orderAll(payload);
    commit("SET_ORDER_ALL", data);
  },
  GET_MORE_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.orderAll(payload);
    commit("SET_MORE_ORDER", data);
  },
  GET_ALL_PRE_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.orderAll(payload);
    commit("SET_PRE_ORDER_ALL", data);
  },
  ADD_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.addOrder(payload);
    return data;
  },
  GET_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.getOrder(payload);
    return data;
  },
  FIND_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.findOrder(payload);
    if (data.type !== "error") {
      commit("SET_SERCH_ORDER", data);
    } else {
      commit("SET_SERCH_ORDER", null);
      showMessage(data.data.message);
    }
  },
  EDIT_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.editOrder(payload);
    return data;
  },
  DELETE_ORDER: async ({ commit }, payload) => {
    let data = await api.Order.deleteOrder(payload);
    return data;
  },
  ORDER_HISTORY: async ({ commit }, payload) => {
    let data = await api.Order.orderHistory(payload);
    return data;
  },
  ORDER_PAYMENT_HISTORY: async ({ commit }, payload) => {
    let data = await api.Order.orderPaymentHistory(payload);
    return data;
  },
  ORDER_EMPLOYEE_HISTORY: async ({ commit }, payload) => {
    let data = await api.Order.orderEmployeeHistory(payload);
    return data;
  },
  EDIT_STATUS: async ({ commit }, payload) => {
    let data = await api.Order.editStatus(payload);
    return data;
  },
  EDIT_STATUS_WITH_TAG: async ({ commit }, payload) => {
    const data = await api.Order.editStatusWithTag(payload);
    return data;
  },
  EDIT_PAYMENT_STATUS: async ({ commit }, payload) => {
    let data = await api.Order.editPaymentStatus(payload);
    return data;
  },
  EDIT_PAYMENT: async ({ commit }, payload) => {
    let data = await api.Order.editPayment(payload);
    return data;
  },
  EDIT_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.Order.editDepartment(payload);
    return data;
  },
  EDIT_PICKER: async ({ commit }, payload) => {
    let data = await api.Order.editPicker(payload);
    return data;
  },
  EDIT_COURIER: async ({ commit }, payload) => {
    let data = await api.Order.editCourier(payload);
    return data;
  },
  GET_STATISTIC: async ({ commit }, payload) => {
    let data = await api.Order.getStatistic(payload);
    return data;
  },
  EDIT_DELIVERY_TIME: async ({ commit }, payload) => {
    let data = await api.Order.editDeliveryTime(payload);
    return data;
  },
    // Проверка возможности отмены Яндекс курьера по orderId
    CHECK_YANDEX_CANCEL: async ({ commit }, orderId) => {
      let data = await api.Order.checkYandexCancel(orderId);
      return data;
    },
    // Отмена Яндекс курьера, payload содержит orderId и cancelState
    CANCEL_YANDEX: async ({ commit }, payload) => {
      let data = await api.Order.cancelYandex(payload.orderId, payload.cancelState);
      return data;
    },
};


export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
