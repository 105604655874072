/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "PRODUCTS";
import singleorder from "@/model/singleorder";
const namespaced = true;

const state = {
  paginatedProducts: [],
  paginatedProductsMVP: {},
  paginatedSearch: [],
  paginatedCode: [],
  productsForModerate: {},
  productsByCategory: [],
  productsByDepartment: []
};
const getters = {
  STATE: (state) => state,
  ProductsMVP: (state) => state.paginatedProductsMVP,
  MODERATED: (state) => state.productsForModerate,
  PRODUCTS_BY_DEPARTMENT: (state) => state.productsByDepartment,
};
const mutations = {
  SET_PAGINATED_PRODUCTS: (state, data) => {
    state.paginatedProducts = data.searchResult;
  },
  SET_PRODUCTS_BY_DEPARTMENT: (state, data) => {
    state.productsByDepartment = data;
  },
  SET_PAGINATED_PRODUCTS_MVP: (state, data) => {
    if (data?.type !== "error") {
      state.paginatedProductsMVP = data;
    }
  },
  SET_PAGINATED_SEARCH: (state, data) => {
    if (data.type === "success") {
      let modal = new singleorder();
      state.paginatedSearch = modal.setInputSearch(data.data);
    }
  },
  SET_PAGINATED_CODE: (state, data) => {
    if (data.type === "success") {
      state.paginatedCode = data.data;
    }
  },
  SET_MODERATE_PRODUCTS: (state, data) => {
    if (data.type !== "error") {
      state.productsForModerate = data;
    }
  },
  CLEAR_PAGINATED_CODE: (state) => {
    state.paginatedCode = [];
  },
};
const actions = {
  GET_PAGINATED_PRODUCTS_MVP: async ({ commit }, payload) => {
    let data = await api.Products.productsPaginationMVP(payload);
    commit("SET_PAGINATED_PRODUCTS_MVP", data);
  },
  GET_PAGINATED_PRODUCTS_MVP_WITH_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Products.productsPaginationMVPWithCategoryes(payload);
    commit("SET_PAGINATED_PRODUCTS_MVP", data);
  },
  // на странице с одиночным заказом
  GET_PAGINATED_PRODUCTS: async ({ commit }, payload) => {
    let data = await api.Products.productsPagination(payload);
    commit("SET_PAGINATED_PRODUCTS", data);
  },
  GET_PRODUCT: async ({ commit }, payload) => {
    let data = await api.Products.getProduct(payload);
    return data;
  },
  ADD_MINIMAL_QUANTITY: async ({ commit }, payload) => {
    let data = await api.Products.addQuantity(payload);
    return data;
  },
  EDIT_MINIMAL_QUANTITY: async ({ commit }, payload) => {
    let data = await api.Products.editQuantity(payload);
    return data;
  },
  GET_ALL_MINIMAL_QUANTITY: async ({ commit }) => {
    let data = await api.Products.getAllMinimalQuantity();
    return data;
  },
  GET_BYID_MINIMAL_QUANTITY: async ({ commit }, payload) => {
    let data = await api.Products.getProductMinimalQuantity(payload);
    return data;
  },
  EDIT_PRODUCT: async ({ commit }, payload) => {
    let data = await api.Products.editProduct(payload);
    return data;
  },
  DELETE_PRODUCT: async ({ commit }, payload) => {
    let data = await api.Products.deleteProduct(payload);
    return data;
  },
  PRODUCT_FIND: async ({ commit }, payload) => {
    let data = await api.Products.productFind(payload);
    commit("SET_PAGINATED_SEARCH", data);
  },
  PRODUCT_FIND_CODE: async ({ commit }, payload) => {
    let data = await api.Products.productCode(payload);
    commit("SET_PAGINATED_CODE", data);
  },
  ADD_IMAGES: async ({ commit }, payload) => {
    let data = await api.Products.addProductImages(payload);
    return data;
  },
  ADD_IMAGES_BY_PROVIDER: async ({ commit }, payload) => {
    let data = await api.Products.addProductImagesByProvider(payload);
    return data;
  },

  GET_ROW: async ({ commit }, payload) => {
    let data = await api.Products.getSingleRow(payload);
    return data.data;
  },
  GET_PRODUCTS_OF_SHOWCASE_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Products.getProductsOfShowcaseCategory(payload);
    return data;
  },
  GET_CATEGORY_PRODUCTS: async ({ commit }, payload) => {
    let data = await api.Products.getCategoryProducts(payload);
    return data;
  },
  ADD_PRODUCT_TO_SHOWCASE: async ({ commit }, payload) => {
    let data = await api.Products.addProductToCategory(payload);
    return data;
  },
  DELETE_PRODUCT_FROM_SHOWCASE: async ({ commit }, payload) => {
    let data = await api.Products.removeProductFromCategory(payload);
    return data;
  },
  CHANGE_SHOWCASE_CATEGORY_PRODUCTS_ORDER: async ({ commit }, payload) => {
    const data = await api.Products.changeShowcaseCategoryProductsOrder(
      payload
    );
    return data;
  },
  // модерация
  GET_PRODUCTS_FOR_MODERATION: async ({ commit }, payload) => {
    let data = await api.Products.getProductsForModeration(payload);
    commit("SET_MODERATE_PRODUCTS", data);
  },
  MODERATE_PRODUCTS: async ({ commit }, payload) => {
    let data = await api.Products.moderateProducts(payload);
    return data;
  },
  GET_PRODUCT_DIFFERENCE: async ({ commit }, payload) => {
    let data = await api.Products.getProductDifference(payload);
    if (data.type !== "error") {
      return data;
    }
  },
  GET_PRODUCTS_BY_DEPARTMENT: async ({ commit }, departmentId) => {
    let data = await api.Products.getProductsByDepartment(departmentId);
    commit("SET_PRODUCTS_BY_DEPARTMENT", data);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
