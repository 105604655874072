/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "CATEGORY";
const namespaced = true;
const state = {
  allCategory: [],
  paginatedCategory: [],
  productsCategory: [],
  specialCategory: [],
  promoCategory: [],
};
const getters = {
  STATE: (s) => s,
  ALL_CATEGORY: (s) => s.allCategory,
  PAGINATED_CATEGORY: (s) => s.paginatedCategory,
  PRODUCTS_CATEGORY: (s) => s.productsCategory,
  SPECIAL_CATEGORY: (s) => s.specialCategory,
  PROMO_CATEGORY: (s) => s.promoCategory,
};
const mutations = {
  SET_CATEGORY_ALL: (state, data) => {
    // сортировка по алфавиту
    state.allCategory = data.sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_PRODUCTS_CATEGORY: (state, data) => {
    state.productsCategory = data.sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_PAGINATED_CATEGORY: (state, data) => {
    state.paginatedCategory = data;
  },
  SET_SPECIAL_CATEGORY: (state, data) => {
    state.specialCategory = data.sort((a, b) => a.name.localeCompare(b.name));
  },
  SET_PROMO_CATEGORY: (state, data) => {
    state.promoCategory = data.sort((a, b) => a.name.localeCompare(b.name));
  },
};
const actions = {
  ADD_NEW_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.addNewCategory(payload);
    return data;
  },
  UPDATE_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.updateCategory(payload);
    return data;
  },
  UPDATE_CATEGORY_OF_PRODUCT: async ({ commit }, payload) => {
    let data = await api.Category.updateCategoryOfProduct(payload);
    return data;
  },
  GET_ALL_CATEGORY: async ({ commit }) => {
    let data = await api.Category.categoryAll();
    commit("SET_CATEGORY_ALL", data);
  },
  GET_PRODUCTS_CATEGORY: async ({ commit }) => {
    let data = await api.Category.categoryForProducts();
    commit("SET_PRODUCTS_CATEGORY", data);
  },
  EDIT_IMAGE: async ({ commit }, payload) => {
    let data = await api.Category.editImage(payload);
    return data;
  },
  GET_PAGINATED_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.categoryPagination(payload);
    commit("SET_PAGINATED_CATEGORY", data);
  },
   // Новый action для фильтрации
   GET_FILTERED_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.filteredCategoryList(payload);
    commit("SET_PAGINATED_CATEGORY", data);
  },
  GET_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.getCategory(payload);
    return data;
  },
  DELETE_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.deleteCategory(payload);
    return data;
  },

  SEARCH_CATEGORY: async ({ commit }, payload) => {
    let data = await api.Category.searchCategory(payload);
    return data;
  },

  // MVP
  GET_ALL_CATEGORY_ON_SHOWCASE: async ({ commit }) => {
    let data = await api.Category.getAllCategoryOnVitrin();
    return data;
  },
  ADD_CATEGORY_ON_SHOWCASE: async ({ commit }, payload) => {
    let data = await api.Category.addCategoryOnVitrin(payload);
    return data;
  },
  DELETE_CATEGORY_ON_SHOWCASE: async ({ commit }, payload) => {
    let data = await api.Category.deleteCategoryOnVitrin(payload);
    return data;
  },
  CHANGE_SHOWCASE_CATEGORIES_ORDER: async ({ commit }, payload) => {
    const data = await api.Category.changeShowcaseCategoriesOrder(payload);
    return data;
  },
   // Новый action для спецальных категорий
   GET_SPECIAL_CATEGORY: async ({ commit }) => {
    let data = await api.Category.getSpecialCategory();
    console.log("Ответ от getSpecialCategory:", data);
    commit("SET_SPECIAL_CATEGORY", data);
  },
  // Новый action для промо категорий
  GET_PROMO_CATEGORY: async ({ commit }) => {
    let data = await api.Category.getPromoCategory();
    commit("SET_PROMO_CATEGORY", data);
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
