import request from "../core/request";

export default {
  getAll() {
    // получение всех групп филиалов
    return request.execute("get", `/admin/DepartmentGroup`);
  },
  addGroup(body) {
    // создание группы
    return request.execute("post", `/admin/DepartmentGroup/add`, body);
  },
  editGroup(body) {
    // редактирование группы
    return request.execute("put", `/admin/DepartmentGroup/edit`, body);
  },
  deleteGroup(id) {
    // удаление группы
    return request.execute("delete", `/admin/DepartmentGroup/${id}`);
  },
  addGroupToDepartment(data) {
    // привязка группы к филиалу
    // Пример эндпоинта: /api/admin/DepartmentGroup/assign?departmentId=XX&groupId=YY
    return request.execute(
      "put",
      `/admin/DepartmentGroup/assign?departmentId=${data.departmentId}&groupId=${data.groupId}`
    );
  },
  removeGroupFromDepartment(departmentId) {
    // отвязка группы от филиала
    // Пример эндпоинта: /api/admin/DepartmentGroup/remove?departmentId=XX
    return request.execute(
      "delete",
      `/admin/DepartmentGroup/remove?departmentId=${departmentId}`
    );
  },
};
