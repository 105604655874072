import request from "../core/request";

export default {
  /**
   * 
   */
  createShowcaseWindow(payload) {
    return request.execute("post", "admin/showcaseWindow/create", payload);
  },

  /**
   * Получение доступных типов витрины для указанного устройства (web/mobile)
   */
  getShowcaseTypes(device) {
    return request.execute("get", `admin/showcaseWindow/getTypes/${device}`);
  },

  /**
   * Получение витрин для указанного устройства и типа витрины.
   * options: { device, type }
   */
  getShowcaseWindow({ device, type }) {
    return request.execute("get", `admin/showcaseWindow/get/${device}/${type}`);
  },

  /**
   * Редактирование витрины.
 
   */
  editShowcaseWindow(payload) {
    return request.execute("put", "admin/showcaseWindow/edit", payload);
  },

  /**
   * Удаление категории из витрины по её id.
   */
  deleteShowcaseCategory(windowCategoryId) {
    return request.execute("delete", `admin/showcaseWindow/delete/category/${windowCategoryId}`);
  },

  /**
   * Удаление продукта из витрины по его id.
   */
  deleteShowcaseProduct(windowProductId) {
    return request.execute("delete", `admin/showcaseWindow/delete/product/${windowProductId}`);
  },

  /**
   * Изменение порядка категорий внутри витрины.
   * payload – массив объектов { id: <id категории на витрине>, position: <новая позиция> }
   */
  updateShowcaseCategoriesOrder(payload) {
    return request.execute("put", "admin/showcaseWindow/edit/categories", payload);
  },

  /**
   * Изменение порядка продуктов внутри витрины.
   * payload – массив объектов { id: <id продукта на витрине>, position: <новая позиция> }
   */
  updateShowcaseProductsOrder(payload) {
    return request.execute("put", "admin/showcaseWindow/edit/products", payload);
  },
  // Новые эндпоинты для добавления категории/продукта в уже существующую витрину
  addShowcaseCategory(windowId, categoryId) {
    return request.execute("put", `admin/showcaseWindow/add/category/${windowId}/${categoryId}`);
  },
  addShowcaseProduct(windowId, productId) {
    return request.execute("put", `admin/showcaseWindow/add/product/${windowId}/${productId}`);
  },
  // Новый эндпоинт для изменения позиций витрин
  updateShowcasePositions(payload) {
    return request.execute("put", "admin/showcaseWindow/edit/editShowcasePositions", payload);
  },
};
