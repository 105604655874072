import request from "../core/request";

export default {
  getRatingLevels() {
    return request.execute("get", `admin/rating/picker/getRatingLevels`);
  },
  getRatingLevelsV2(departmentId) {
    return request.execute(
      "get",
      `admin/order-pack/level/byDepartment/${departmentId}`
    );
  },
  setRatingLevel2(input) {
    return request.execute("put", `/admin/order-pack/level/edit`, input);
  },
  setRatingLevel(input) {
    return request.execute("put", `admin/rating/picker/setRatingLevel`, {
      ...input,
    });
  },
  createRatingLevel(input) {
    return request.execute("post", `admin/order-pack/level/insert`, input);
  },
  getRewardByDepartment(departmentId) {
    return request.execute(
      "get",
      `/admin/shift/shiftReward/byDepartment/${departmentId}`
    );
  },
  createRevard(payload) {
    return request.execute("post", `/admin/shift/shiftReward/add`, payload);
  },
  changeRevard(input) {
    return request.execute("put", `admin/shift/shiftReward/edit`, input);
  },
  //   метод для получения сборщиков
  getPicker(params) {
    return request.execute("post", `admin/picker/all/city/`, params);
  },
  employeeCity(id) {
    return request.execute("get", `admin/employee/all/${id}`);
  },
  deletedEmployeeCity(cityId) {
    return request.execute("get", `admin/employee/all/deleted/${cityId}`);
  },
  restoreEmployee(employeeId) {
    return request.execute("get", `admin/employee/recover/${employeeId}`);
  },
  addEmployee(input) {
    return request.execute("post", "admin/employee/add", { ...input });
  },
  getEmployee(id) {
    return request.execute("get", `admin/employee/${id}`);
  },
  editEmployee(input) {
    return request.execute("put", "admin/employee/update", input);
  },
  deleteEmployee(id) {
    return request.execute("delete", `admin/employee/delete/${id}`);
  },
  getCourierListMap() {
    return request.execute("get", "admin/courier/all");
  },
  getCourierListMapOrder(data) {
    return request.execute("get", `admin/courier/order/all/${data.id}`);
  },
 //   метод для получения курьеров
 getCourier(params) {
  return request.execute("post", `admin/courier/all/city/`, params);
},
  getProfile(employeeId) {
    return request.execute("get", `admin/employee/profile/${employeeId}`);
  },
  addPenalty(body) {
    return request.execute("post", `admin/penalty/add/variousPenalty`, body);
  },
  // только для курьеров нужно
  getAllContracts() {
    return request.execute("get", `admin/employee/contracts/all`);
  },
};
