import request from "../core/request";

export default {
  allCouriersTypes() {
    return request.execute("get", `admin/courierType/all`);
  },
  allCouriersList() {
    return request.execute("get", `admin/courierType/couriers/all`);
  },
  addCourierType(objId) {
    return request.execute(
      "put",
      `admin/courierType/setTypeForCourier?courierId=${objId.courierId}&courierTypeId=${objId.typeId}`
    );
  },
  // Для изменения типа курьера
  //   {id:number,
  //   name:string,
  //   speed:number,
  //   maxDistance:number,
  //   basketSize:number,
  //   weight:number,
  //   deliveryTrust:string,
  //   maxOrders:number,
  //   profile:string,
  //   priority:number
  // }
  editCourierType(body) {
    return request.execute("put", `admin/courierType/edit`, body);
  },
  //body такое же , только без id
  createCourierType(body) {
    return request.execute("post", `admin/courierType/add`, body);
  },
  deleteCourierType(id) {
    return request.execute("delete", `admin/courierType/delete/${id}`);
  },
  getAllDeliveryZones() {
    return request.execute("get", `admin/courierDeliveryZone/all`);
  },
  // body{
  //   name:String,
  //   coords:[[string]]
  // }
  addDeliveryZone(body) {
    return request.execute("post", `admin/courierDeliveryZone/add`, body);
  },
  // body{
  //   userId:Number,
  //   zoneId:number
  // }
  setDeliveryZoneToCourier(body) {
    return request.execute("post", `admin/user/set/deliveryZone`, body);
  },
  // body{
  //   userId:Number,
  //   departmentIds:number[]

  // }
  setSelfZonedDeliveryZoneToCourier(body) {
    return request.execute("post", `admin/user/set/courier/selfZoned`, body);
  },
  getAllDeliveryZoneDepartment(body) {
    return request.execute("get", `admin/department/selfZoned/list`);
  },
  // body{
  //   name:String,
  //   coords:[[string]]
  // }
  editDeliveryZone(body) {
    return request.execute("put", `admin/courierDeliveryZone/edit`, body);
  },
  deleteDeliveryZone(zoneId) {
    return request.execute(
      "delete",
      `admin/courierDeliveryZone/delete/${zoneId}`
    );
  },
  deleteCouriersDeliveryZone(courierId) {
    return request.execute(
      "get",
      `/admin/user/set/deliveryZone/null/${courierId}`
    );
  },
};

