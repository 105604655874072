/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "BANNER";
const namespaced = true;
const state = {
  allBanners: [],
};
const getters = {
  STATE: (state) => state,
};
const mutations = {
  SET_BANNERS: (state, data) => {
    state.allBanners = data;
  },
  UPDATE_BANNERS_POSITION: (state, data) => {
    let allBannersArray = data.map((el, index) => {
      el.position = index + 1;
      return el;
    });
    state.allBanners = allBannersArray;
  },
  REMOVE_BANNER: (state, bannerId) => {
    let removedBannerIndex;
    state.allBanners.forEach((el) => {
      if (el.id === bannerId) {
        removedBannerIndex = el.position;
      }
    });
    state.allBanners.splice(removedBannerIndex - 1, 1);
  },
  REMOVE_BANNERS: (state, bannerIds) => {
    bannerIds.forEach((bannerId) => {
      let removedBannerIndex = state.allBanners.findIndex((el) => {
        if (el.id === bannerId) return el.index;
      });
      state.allBanners.splice(removedBannerIndex, 1);
    });
  },
};
const actions = {
  GET_BANNER_LIST_BY_DEPARTMENT: async ({ commit }, departmentId) => {
    let data = await api.Banner.getBannerList(departmentId);
    commit("SET_BANNERS", data.data);
  },
  GET_CATEGORY_BANNERS: async ({ commit }, cityId) => {
    let data = await api.Banner.getCategoryBanners(cityId);
    commit("SET_BANNERS", data.data);
  },

  // Обновляем каждый баннер по отдельности через новый API-метод updateBanner
  UPDATE_BANNER_LIST: async ({ state }) => {
    const updatePromises = state.allBanners.map((banner) => {
      // Каждый баннер должен содержать все необходимые поля:
      // id, name, position, departmentId, storeOpeningDepartment, categoryId, bannerTypeId, published
      return api.Banner.updateBanner(banner);
    });
    return Promise.all(updatePromises);
  },

  DELETE_BANNER: async ({ commit }, bannerId) => {
    let data = await api.Banner.deleteBanner(bannerId);
    if (data.type === "success") {
      commit("REMOVE_BANNER", bannerId);
      return true;
    } else {
      return false;
    }
  },
  GET_BANNER_IMAGE: async ({ commit }, input) => {
    let data = await api.Banner.getBannerImage(input);
    if (data.type === "success") {
      return data;
    }
  },
  CREATE_BANNER: async ({ commit }, input) => {
    // Формируем payload с дополнительными характеристиками
    const payload = {
      name: input.name,
      departmentId: input.departmentId,
      published: input.published !== undefined ? input.published : true,
      storeOpeningDepartment:
        input.storeOpeningDepartment !== undefined
          ? input.storeOpeningDepartment
          : input.departmentId,
      categoryId: input.categoryId || null,
      bannerTypeId: input.bannerTypeId || null,
    };
    let data = await api.Banner.createBanner(payload);
    return data;
  },
  // Если баннер для категории создаётся отдельно,
  // можно использовать метод createCategoryBanner (с дефолтными значениями)
  CREATE_CATEGORY_BANNER: async ({ commit }, input) => {
    const payload = {
      name: input.name,
      departmentId: input.departmentId,
      categoryId: input.categoryId || null,
      bannerTypeId: input.bannerTypeId || null,
    };
    let data = await api.Banner.createCategoryBanner(payload);
    return data;
  },
  ADD_BANNER_IMAGE: async ({ commit }, input) => {
    // Подготовка файлов: теперь ожидаем три файла: desktop, mobile, mobileWeb
    const localFile = [];
    if (input.desktop === undefined) {
      input.desktop = { name: "" };
    } else {
      localFile.push(input.desktop);
    }
    if (input.mobile === undefined) {
      input.mobile = { name: "" };
    } else {
      localFile.push(input.mobile);
    }
    if (input.mobileWeb === undefined) {
      input.mobileWeb = { name: "" };
    } else {
      localFile.push(input.mobileWeb);
    }
    const localFormData = new FormData();
    localFile.forEach((element) => {
      localFormData.append(
        "file",
        element,
        input.departmentId + input.name + element.name
      );
    });
    const desktopImageName =
      input.departmentId + input.name + input.desktop.name;
    const mobileImageName =
      input.departmentId + input.name + input.mobile.name;
    const mobileWebImageName =
      input.departmentId + input.name + input.mobileWeb.name;
    const localInput = {
      bannerId: input.id,
      desktopImageName,
      mobileImageName,
      mobileWebImageName,
      file: localFormData,
    };
    let data = await api.Banner.addImageForBanner(localInput);
    return data.type;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
